import './style.css';
//import {Map, View} from 'ol';
//import TileLayer from 'ol/layer/Tile';
//import OSM from 'ol/source/OSM';
import ImageLayer from 'ol/layer/Image';
import LayerGroup from 'ol/layer/Group';
import Map from 'ol/Map.js';
import OSM from 'ol/source/OSM.js';
import TileImage from 'ol/source/TileImage';
import TileLayer from 'ol/layer/Tile.js';
import TileWMS from 'ol/source/TileWMS.js';
import View from 'ol/View.js';
import XYZ from 'ol/source/XYZ';
//import {FullScreen, defaults as defaultControls} from 'ol/control.js';
import {ZoomToExtent, defaults as defaultControls} from 'ol/control.js';

const OpenStreetMap = new TileLayer({
  title:'OpenStreetMap',
  source: new OSM()
});

const Raster = new TileLayer({
  title:'Imagem Satelite',
  source: new XYZ({
    url: 'https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=jB46XgrA4uuCBgkOjfGH',
  })
});

const Topografia = new TileLayer({
  title:'Superfícia Topográfica',
  source: new XYZ({
    url: 'https://api.maptiler.com/maps/topo-v2/{z}/{x}/{y}.png?key=jB46XgrA4uuCBgkOjfGH',
  })
});

const FaceDeQuadra = new TileLayer({  
  title: 'Face de Quadra',                    
  source: new TileWMS({                      
    url: 'http://localhost:8080/geoserver/SJVRP/wms',
    params: {'LAYERS':'SJVRP:FaceDeQuadra','TILED': true},
    serverType: 'geoserver',
    transition: 0      
  })         
});

const Edificacao = new TileLayer({  
  title: 'Edificacao de Sao Jose',                    
  source: new TileWMS({                      
    url: 'http://localhost:8080/geoserver/SJVRP/wms',
    params: {'LAYERS': 'SJVRP:Edificacao', 'TILED': true},
    serverType: 'geoserver',
    transition: 0      
  })         
});

const Limite = new TileLayer({  
  title: 'Limite Municipal de São José',                    
  source: new TileWMS({                      
    url: 'http://localhost:8080/geoserver/SJVRP/wms',
    params: {'LAYERS':'SJVRP:LimiteMunicipal','TILED': true},    
    serverType: 'geoserver',
    transition: 0      
  })         
});

const wmsSource = new TileLayer({
  title: 'States of America',
  source:new TileWMS ({
    url: 'http://localhost:8080/geoserver/topp/wms',
    params: {'LAYERS': 'topp:states'},
    ratio: 1,
    serverType: 'geoserver',
    transition: 0 
  })  
});

//Funções da legenda
const updateLegend = function (resolution) {
  const graphicUrl = wmsSource.getLegendUrl(resolution);
  const img = document.getElementById('legend');
  img.src = graphicUrl;
};

// Variável de mapa principal
const map = new Map({
  target: 'map', 
  controls: defaultControls().extend([
    new ZoomToExtent({
      extent: [
        //-2520482.7791264898, -5929220.284081122, -8966.9639063801,
        //-5936863.986909639,
        //-4799999.0523, -2600000.6419,-4763000.0000, 
        //-2455000.0000, 
        -4799999.0523, -2527500.6419,-4763000.0000, 
        -2527500.0000,         
        //696209.818630 7535985.400151 737673.486129 7556931.223684
      ],
    }),
  ]), 
  layers: new LayerGroup({
    layers: [OpenStreetMap, Raster, Topografia, FaceDeQuadra, Edificacao, Limite, wmsSource],
  }),    
  view: new View({
    center: [-4763000.0000, -2527500.0009],//[-4863000,-2603500],
    zoom: 16
  })
});

/*
function toggleLayer(eve){
  var lyrname = eve.target.value;
  var checkedStatus = eve.target.checked;
  var lyrList = map.getLayers();

  lyrList.forEach(function(elment){
    if (lyrname == element.get('title')){
      element.setVisible(checkedStatus);
    }
  });
}
*/


// ESSE É O COMANDO DE SELECIONAR LAYER
function bindInputs(layerid, layer) {
  const visibilityInput = $(layerid + ' input.visible');
  visibilityInput.on('change', function () {
    layer.setVisible(this.checked);
  });
  visibilityInput.prop('checked', layer.getVisible());

  const opacityInput = $(layerid + ' input.opacity');
  opacityInput.on('input', function () {
    layer.setOpacity(parseFloat(this.value));
  });
  opacityInput.val(String(layer.getOpacity()));
}

function setup(id, group) {
  group.getLayers().forEach(function (layer, i) {
    const layerid = id + i;
    bindInputs(layerid, layer);
    if (layer instanceof LayerGroup) {
      setup(layerid, layer);
    }
  });
}
setup('#layer', map.getLayerGroup());

$('#layertree li > span')
  .click(function () {
    $(this).siblings('fieldset').toggle();
  })
  .siblings('fieldset')
  .hide();
//----------------------------------------------------------

// Esse é parte da Legenda
const resolution = map.getView().getResolution();
updateLegend(resolution);

// Update the legend when the resolution changes
map.getView().on('change:resolution', function (event) {
  const resolution = event.target.getResolution();
  updateLegend(resolution);
});

// ESSE É A FERRAMENTA DE HOME
/*
const homeButton = document.createElement('button');
homeButton.innerHTML = '<img src="resources/images/home.svg" alt="style="width:20px;filter:brightness(0) invert(1);vertical-align:midle"></img>';
homeButton.className = 'myButton';

const homeElement = document.createElement('div');
homeElement.className = 'homeButtonDiv';
homeElement.appendChild(homeButton);

const homeControl = new Control({
  element: homeElement
});

homeButton.addEventListener("click", () => {
  location.href = "index.html";
});

map.addControl(homeControl);
*/
//----------------------------------------------------------


// Adicione uma interação de seleção para a camada
// Isso veio do chat gpt!
/*
var selectEdificacao = new ol.interaction.Select({
  layers: [Edificacao], // A camada que você deseja consultar
  style: new ol.style.Style({
      stroke: new ol.style.Stroke({
          color: 'red', // Cor da borda das features selecionadas
          width: 2
      }),
      fill: new ol.style.Fill({
          color: 'rgba(255, 0, 0, 0.1)' // Cor do preenchimento das features selecionadas
      })
  })
});
map.addInteraction(selectEdificacao);

// Adicione um evento para lidar com a seleção
selectEdificacao.on('select', function (e) {
  var selectedFeatures = e.selected; // Features selecionadas

  if (selectedFeatures.length > 0) {
      // Aqui você pode processar as features selecionadas e exibir informações relevantes
      var selectedFeature = selectedFeatures[0];
      var properties = selectedFeature.getProperties();

      // Por exemplo, exiba as propriedades da feature no console
      console.log(properties);

      // Ou você pode exibir informações em um elemento HTML na página
      var infoElement = document.getElementById('info');
      infoElement.innerHTML = JSON.stringify(properties, null, 2);
  }
});
*/
//----------------------------------------------------------